import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Checkbox,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  getDefaultReservationData,
  getReservationInformationAPI,
} from '../../../services/api/analyticsApi';
import DataRangeFilter from '../../../components/analytics/DataRangeFilter';
import {
  DefaultReservationAnalytics,
  PremiseAnalyticsResponseModel,
  UserAnalyticsResponseModel,
} from '../../../models/analytics/reservationAnalytics';
import { Status } from '../../../models/reservation/status';
import {
  ReservationAnalyticsTimeRangeContainer,
  ReservationsContainer,
  SortByContainer,
  StyledTableHeaderCell,
  StyledTextField,
  TimeRangeButtons,
} from './Reservations.style';
import { getDateRange } from '../../../helpers/date.helpers';
import { Column, Row } from '../../../utils/layout';
import { getAnalytics } from '../../../helpers/analytics.helper';
import { Reservation } from '../../../models/reservation/reservation';

// New constant for time ranges
const timeRanges = [
  { value: 'thisWeek', label: 'This Week' },
  { value: 'lastWeek', label: 'Last Week' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'lastMonth', label: 'Last Month' },
];

// Extract only text-based keys from the Status enum
const statusOptions = [
  { value: 'all', label: 'All' },
  ...Object.entries(Status)
    .filter(([key, value]) => !Number.isNaN(Number(key))) // Filter out reverse-mapped numeric keys
    .map(([key, value]) => ({ value, label: key })), // Map to dropdown options
];
const sortByOptions = ['Start', 'CreatedOn'];
const CustomerDetails = ({
  customer,
  onCheckReservations,
}: {
  customer: UserAnalyticsResponseModel;
  onCheckReservations: () => void;
}) => (
  <div>
    <p>
      <strong>Name:</strong> {customer.name} {customer.surname}
    </p>
    <p>
      <strong>Email:</strong> {customer.email}
    </p>
    <p>
      <strong>Phone:</strong> {customer.phone}
    </p>
    <Button variant='contained' color='primary' onClick={onCheckReservations}>
      Check User Reservations
    </Button>
  </div>
);

const PremiseDetails = ({
  premise,
  onCheckReservations,
}: {
  premise: PremiseAnalyticsResponseModel;
  onCheckReservations: () => void;
}) => (
  <div>
    <p>
      <strong>Name:</strong> {premise.name}
    </p>
    <p>
      <strong>Address:</strong> {premise.address}
    </p>
    <p>
      <strong>Manager Name:</strong> {premise.managerData.name} {premise.managerData.surname}
    </p>
    <p>
      <strong>Manager Email:</strong> {premise.managerData.email}
    </p>
    <p>
      <strong>Manager Phone:</strong> {premise.managerData.phone}
    </p>
    <Button variant='contained' color='primary' onClick={onCheckReservations}>
      Check Premise Reservations
    </Button>
  </div>
);

const ReservationDetails = ({ reservation }: { reservation: Reservation }) => (
  <div>
    <p>
      <strong>Reservation ID:</strong> {reservation.id}
    </p>
    <p>
      <strong>Customer:</strong> {`${reservation?.user?.name} ${reservation?.user?.surname}`}
    </p>
    <p>
      <strong>Start Date:</strong> {moment(reservation.start).format('DD.MM.YYYY')}
    </p>
    <p>
      <strong>End Date:</strong> {moment(reservation.end).format('DD.MM.YYYY')}
    </p>
    <p>
      <strong>Status:</strong> {Status[reservation.status]}
    </p>
    <p>
      <strong>Table:</strong> {reservation.table?.name || 'no table'}
    </p>
    <p>
      <strong>IsDining:</strong> {reservation.isDining ? 'dining' : 'drinks'}
    </p>
  </div>
);

const ReservationsTable = () => {
  const [analyticsData, setAnalyticsData] = useState<DefaultReservationAnalytics[]>([]);
  const [fromDate, setFromDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 7)),
  );
  const [toDate, setToDate] = useState<Date>(new Date());
  const [sortBy, setSortBy] = useState('Start');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // New state for filters
  const [filters, setFilters] = useState({
    customerName: '',
    customerEmail: '',
    premiseName: '',
    reservationDate: '',
    createdOn: '',
    status: '',
  });

  const [timeRange, setTimeRange] = useState<string>(timeRanges[0].value);

  // New state for selected statuses
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(['all']);

  const [selectedCustomer, setSelectedCustomer] = useState<UserAnalyticsResponseModel | null>(null);
  const [selectedPremise, setSelectedPremise] = useState<PremiseAnalyticsResponseModel | null>(
    null,
  );
  const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpenCustomerDialog = (customer: UserAnalyticsResponseModel) => {
    setSelectedCustomer(customer);
    setSelectedPremise(null);
    setIsDialogOpen(true);
  };

  const handleOpenPremiseDialog = (premise: PremiseAnalyticsResponseModel) => {
    setSelectedPremise(premise);
    setSelectedCustomer(null);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedCustomer(null);
    setSelectedPremise(null);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, sortBy]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getDefaultReservationData({ fromDate, toDate, sortBy });
      setAnalyticsData(data);
    } catch (err) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (newFromDate: Date | null, newToDate: Date | null) => {
    if (newFromDate) setFromDate(newFromDate);
    if (newToDate) setToDate(newToDate);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>, column: string) => {
    setFilters({ ...filters, [column]: event.target.value });
  };

  const handleTimeRangeChange = (event: any) => {
    const value = event.target.value as string;
    setTimeRange(value);
    try {
      const { fromDate: newFromDate, toDate: newToDate } = getDateRange(value); // Use the helper function
      setFromDate(newFromDate);
      setToDate(newToDate);
      handleDateChange(newFromDate, newToDate);
    } catch (error) {
      console.error(error);
    }
  };
  const handleStatusChange = (event: SelectChangeEvent<typeof selectedStatuses>) => {
    const { value } = event.target;
    const newSelected = typeof value === 'string' ? value.split(',') : [...value];

    const lastSelected = newSelected[newSelected.length - 1];

    if (lastSelected === 'all') {
      // If "All" is selected, clear everything else and keep only "All"
      setSelectedStatuses(['all']);
    } else {
      // If "All" is currently selected, deselect it
      const filteredStatuses = newSelected.filter(status => status !== 'all');

      // Update state with all selected values except "All"
      setSelectedStatuses(filteredStatuses);
    }
  };

  const filteredData = analyticsData.filter(item => {
    return (
      item.customerData?.name?.toLowerCase().includes(filters.customerName.toLowerCase()) &&
      item.customerData?.email?.toLowerCase().includes(filters.customerEmail.toLowerCase()) &&
      item.premiseData?.name?.toLowerCase().includes(filters.premiseName.toLowerCase()) &&
      new Date(item.start).toLocaleDateString().includes(filters.reservationDate) &&
      new Date(item.createdOn).toLocaleDateString().includes(filters.createdOn) &&
      (selectedStatuses.length === 0 ||
        selectedStatuses.includes(Status[item.status]) ||
        selectedStatuses.includes('all'))
    );
  });

  const handelSelectedUserReservationsClicked = () => {
    alert('not yet implemented ');
  };
  const handleSelectedPremiseReservationsClicked = () => {
    if (selectedPremise) {
      navigate(`/hostAdmin/reservation-analytics/${selectedPremise.id}`);
    } else {
      alert('No premise selected');
    }
  };
  // Call the analytics function with the filtered data and log the results
  const analyticsResults = getAnalytics(filteredData);

  const getReservationInformation = (id: string) => {
    getReservationInformationAPI(id).then(data => {
      if (data) {
        setSelectedReservation(data);
        setIsDialogOpen(true);
      }
    });
  };

  if (error) return <Typography color='error'>{error}</Typography>;

  return (
    <ReservationsContainer>
      <Typography variant='h6'>Reservation Analytics</Typography>
      <Row>
        <ReservationAnalyticsTimeRangeContainer style={{ height: '15vh' }}>
          <DataRangeFilter
            onChange={handleDateChange}
            fromDateValue={fromDate}
            toDateValue={toDate}
          />
          <Row style={{ justifyContent: 'space-between' }}>
            <TimeRangeButtons>
              {timeRanges.map(range => (
                <Button
                  style={{ margin: 3, height: 25 }}
                  key={range.value}
                  variant={timeRange === range.value ? 'contained' : 'outlined'}
                  onClick={() => handleTimeRangeChange({ target: { value: range.value } })}
                >
                  {range.label}
                </Button>
              ))}
            </TimeRangeButtons>
            <SortByContainer>
              <p>Sort By</p>
              {sortByOptions.map(option => (
                <Button
                  key={option}
                  variant={sortBy === option ? 'contained' : 'outlined'}
                  onClick={() => setSortBy(option)}
                  style={{ margin: 3, height: 25 }}
                >
                  {option}
                </Button>
              ))}
            </SortByContainer>
          </Row>
        </ReservationAnalyticsTimeRangeContainer>
        <Column style={{ padding: '16px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
          <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Total filtered reservations: {filteredData.length}
          </p>
          <p style={{ fontWeight: 'bold' }}>Most reservations</p>
          <p>
            Premise:{' '}
            <span style={{ color: 'blue' }}>{analyticsResults.mostReservedPremise[0]}</span> (
            {analyticsResults.mostReservedPremise[1]})
          </p>
          <p>
            User: <span style={{ color: 'blue' }}>{analyticsResults.mostReservedUser.name}</span> (
            {analyticsResults.mostReservedUser.count})
          </p>
          <p>
            Day: <span style={{ color: 'blue' }}>{analyticsResults.mostReservedDay[0]}</span> (
            {analyticsResults.mostReservedDay[1]})
          </p>
        </Column>
      </Row>

      <TableContainer
        component={Paper}
        sx={{ height: '70vh', overflow: 'auto', border: '1px solid black' }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ position: 'sticky', top: 0, background: 'white' }}>
              <StyledTableHeaderCell>
                <StyledTextField
                  placeholder='Customer Name'
                  value={filters.customerName}
                  hasValue={!!filters.customerName}
                  onChange={(e: any) => handleFilterChange(e, 'customerName')}
                />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                <StyledTextField
                  placeholder='Customer Email'
                  value={filters.customerEmail}
                  hasValue={!!filters.customerEmail}
                  onChange={(e: any) => handleFilterChange(e, 'customerEmail')}
                />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                <StyledTextField
                  placeholder='Premise Name'
                  value={filters.premiseName}
                  hasValue={!!filters.premiseName}
                  onChange={(e: any) => handleFilterChange(e, 'premiseName')}
                />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                <StyledTextField
                  placeholder='Start'
                  value={filters.reservationDate}
                  hasValue={!!filters.reservationDate}
                  onChange={(e: any) => handleFilterChange(e, 'reservationDate')}
                />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                <StyledTextField
                  placeholder='Created On'
                  value={filters.createdOn}
                  hasValue={!!filters.createdOn}
                  onChange={(e: any) => handleFilterChange(e, 'createdOn')}
                />
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                <Select
                  multiple
                  value={selectedStatuses}
                  onChange={handleStatusChange}
                  renderValue={selected =>
                    selected
                      .map(
                        status => (status === 'all' ? 'All' : status), // Display 'All' for the 'all' value
                      )
                      .join(', ')
                  }
                  sx={{
                    width: '180px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    border:
                      selectedStatuses.length > 0 && selectedStatuses[0] !== 'all'
                        ? 'px solid red'
                        : '',
                  }}
                >
                  {statusOptions.map(option => (
                    <MenuItem key={option.label} value={option.value}>
                      <Checkbox checked={selectedStatuses.includes(option.value.toString())} />
                      <ListItemText primary={option.value} />
                    </MenuItem>
                  ))}
                </Select>
              </StyledTableHeaderCell>
              <StyledTableHeaderCell style={{ maxWidth: 80 }}>
                <p>Info</p>
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableBody>
              {filteredData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                      onClick={() => handleOpenCustomerDialog(item.customerData)}
                    >
                      {item.customerData?.name || 'N/A'} {item.customerData?.surname || ''}
                    </Typography>
                  </TableCell>
                  <TableCell>{item.customerData?.email || 'N/A'}</TableCell>
                  <TableCell>
                    <Typography
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                      onClick={() => handleOpenPremiseDialog(item.premiseData)}
                    >
                      {item.premiseData?.name || 'N/A'}
                    </Typography>
                  </TableCell>{' '}
                  <TableCell>{moment(item.start).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>{moment(item.createdOn).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>{Status[item.status]}</TableCell>
                  <TableCell>
                    <Typography
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                      onClick={() => getReservationInformation(item.id)}
                    >
                      More info
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedCustomer
            ? 'Customer Details'
            : selectedPremise
            ? 'Premise Details'
            : 'Reservation Details'}
        </DialogTitle>
        <DialogContent>
          {selectedCustomer && (
            <CustomerDetails
              customer={selectedCustomer}
              onCheckReservations={handelSelectedUserReservationsClicked}
            />
          )}
          {selectedPremise && (
            <PremiseDetails
              premise={selectedPremise}
              onCheckReservations={handleSelectedPremiseReservationsClicked}
            />
          )}
          {selectedReservation && <ReservationDetails reservation={selectedReservation} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ReservationsContainer>
  );
};

export default ReservationsTable;
