import moment from 'moment';
import { DefaultReservationAnalytics } from '../models/analytics/reservationAnalytics';
import { formatDefaultDate } from './date.helpers';

export const getAnalytics = (analyticsData: DefaultReservationAnalytics[]) => {
  const premiseCount: { [key: string]: number } = {};
  const userCount: { [key: string]: number } = {};
  const userNames: { [key: string]: string } = {}; // Map user ID to name + surname
  const dayCount: { [key: string]: number } = {};

  analyticsData.forEach(item => {
    // Count reservations per premise
    const premiseName = item.premiseData?.name || 'Unknown Premise';
    premiseCount[premiseName] = (premiseCount[premiseName] || 0) + 1;

    // Check by user ID and store user name and surname
    const userId = item.customerData?.id || 'Unknown User ID';
    const userName = item.customerData?.surname
      ? `${item.customerData.name} ${item.customerData.surname}`
      : 'Unknown User';
    userCount[userId] = (userCount[userId] || 0) + 1;
    userNames[userId] = userName; // Store the full name for the user ID

    // Count reservations per day
    const reservationDate = formatDefaultDate(item.start);
    dayCount[reservationDate] = (dayCount[reservationDate] || 0) + 1;
  });

  // Find the most reserved premise
  const mostReservedPremise = Object.entries(premiseCount).reduce(
    (a, b) => (b[1] > a[1] ? b : a),
    ['', 0],
  );

  // Find the user with the most reservations
  const mostReservedUserEntry = Object.entries(userCount).reduce(
    (a, b) => (b[1] > a[1] ? b : a),
    ['', 0],
  );
  const mostReservedUser = {
    id: mostReservedUserEntry[0],
    name: userNames[mostReservedUserEntry[0]] || 'Unknown User',
    count: mostReservedUserEntry[1],
  };

  // Find the day with the most reservations
  const mostReservedDay = Object.entries(dayCount).reduce((a, b) => (b[1] > a[1] ? b : a), ['', 0]);

  return {
    mostReservedPremise,
    mostReservedUser,
    mostReservedDay,
  };
};
