import { Select, TableCell, TableRow, TextField } from '@mui/material';
import styled from 'styled-components';
import { Column, Row } from '../../../utils/layout';

export const ReservationsContainer = styled.div`
  width: 100%;

  overflow: auto;
`;
export const ReservationAnalyticsTimeRangeContainer = styled(Column)`
  width: 70%;
  justify-content: space-between;
  align-self: center;
  margin: 15px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
`;
export const TimeRangeButtons = styled(Row)`
  width: 50%;
`;

export const StyledTableHeaderCell = styled(TableCell)`
  font-weight: bold !important; /* Make text bold */
  background-color: #cacaca; /* Light gray background */
  text-align: center;
  width: 180px;
`;

export const StyledTextField = styled(TextField)<{ hasValue: boolean }>`
  & .MuiOutlinedInput-root {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ hasValue }) => (hasValue ? 'red' : 'inherit')};
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #fafafa; /* Light alternating row color */
  }
  &:nth-of-type(even) {
    background-color: #ffffff; /* White alternating row color */
  }
`;

export const SortByContainer = styled(Row)`
  align-items: center;
  justify-content: center;
`;
