import {
  PremiseAnalyticsRequestModel,
  PremiseAnalyticsResponseModel,
} from '../../models/analytics/premiseAnalytics';
import {
  DefaultReservationAnalytics,
  ReservationAnalyticsResponseModel,
  ReservationAnalyticsTimeRangeRequest,
} from '../../models/analytics/reservationAnalytics';
import { GiveawayResponseModel, GiveawayRequestModel } from '../../models/giveaway/giveaway';
import { Reservation } from '../../models/reservation/reservation';
import axiosInstance from '../networkAxios';

export const getPremisesAnalytics = async (
  request: PremiseAnalyticsRequestModel,
): Promise<PremiseAnalyticsResponseModel[]> => {
  try {
    const response = await axiosInstance.post('/analytics/premise', request);
    return response.data;
  } catch (error) {
    console.error('Error fetching premises analytics:', error);
    throw error;
  }
};

export const getReservationAnalytics = async (
  premiseId: string,
): Promise<ReservationAnalyticsResponseModel[]> => {
  try {
    const response = await axiosInstance.get(`/analytics/reservation/${premiseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation analytics:', error);
    throw error;
  }
};

export const getGiveawayData = async (
  request: GiveawayRequestModel,
): Promise<GiveawayResponseModel[]> => {
  try {
    const response = await axiosInstance.get('/analytics/giveaway', {
      params: {
        fromDate: request.fromDate ? request.fromDate.toISOString() : null,
        toDate: request.toDate ? request.toDate.toISOString() : null,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching giveaway data:', error);
    throw error;
  }
};

export const getDefaultReservationData = async (
  request: ReservationAnalyticsTimeRangeRequest,
): Promise<DefaultReservationAnalytics[]> => {
  try {
    const response = await axiosInstance.get('/analytics/reservation-timerange', {
      params: {
        fromDate: request.fromDate.toISOString(),
        toDate: request.toDate.toISOString(),
        sortBy: request.sortBy,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching default reservation data:', error);
    throw error;
  }
};

export const getReservationInformationAPI = async (id: string): Promise<Reservation> => {
  try {
    const response = await axiosInstance.get(`/reservation/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservation data', error);
    throw error;
  }
};
