import moment from 'moment';

// Set the locale globally to start the week on Monday
moment.updateLocale('en', {
  week: {
    dow: 1, // Set Monday as the first day of the week (0 = Sunday, 1 = Monday)
  },
});

type DateRange = { fromDate: Date; toDate: Date };

const dateRangeStrategies: Record<string, () => DateRange> = {
  thisWeek: () => ({
    fromDate: moment().startOf('week').toDate(),
    toDate: moment().endOf('week').toDate(),
  }),
  lastWeek: () => ({
    fromDate: moment().subtract(1, 'week').startOf('week').toDate(),
    toDate: moment().subtract(1, 'week').endOf('week').toDate(),
  }),
  thisMonth: () => ({
    fromDate: moment().startOf('month').toDate(),
    toDate: moment().endOf('month').toDate(),
  }),
  lastMonth: () => ({
    fromDate: moment().subtract(1, 'month').startOf('month').toDate(),
    toDate: moment().subtract(1, 'month').endOf('month').toDate(),
  }),
};

export const getDateRange = (value: string): DateRange => {
  const strategy = dateRangeStrategies[value];
  if (!strategy) {
    throw new Error('Invalid time range');
  }
  return strategy();
};

// Helper method to format date in dd.MM.yyyy format
export const formatDefaultDate = (date: Date): string => {
  return moment(date).format('DD.MM.YYYY');
};
