import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import {
  AUTH_BASE_ROUTE,
  BASE_ROUTE,
  DASHBOARD,
  HowItWorks_Customer,
  HowItWorks_Manager,
  MAP,
  PREMISE,
  USER,
  RESERVATION_ANALYTICS,
  GIVEAWAY,
  NOTIFICATIONS,
  RESERVATIONS,
} from './services/routes';
import Auth from './views/auth/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProtectedRoute, { ProtectedRouteProps } from './guard/ProtectedRoute';
import Dashboard from './views/host-admin/dashboard/Dashboard';
import HostAdmin from './views/host-admin/HostAdminPage';
import Premise from './views/host-admin/premise/get/Premises';
import Users from './views/host-admin/user/get/Users';
import CustomModal from './utils/customModal/CustomModal';
import MapComponent from './views/host-admin/map/Map';
import { Linking } from './views/linking/Linking';
import ReservationAnalytics from './views/host-admin/analytics/ReservationAnalytics';
import Giveaway from './views/host-admin/giveaway/Giveaway';
import Notifications from './views/host-admin/notifications/Notifications';
import Reservations from './views/host-admin/reservations/Reservation';

function App() {
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    authenticationPath: AUTH_BASE_ROUTE,
  };

  return (
    <div className='App'>
      <Routes>
        <Route path='/linking/:customurl' element={<Linking />} />
        <Route path={AUTH_BASE_ROUTE} element={<Auth />} />
        <Route path={BASE_ROUTE} element={<HostAdmin />}>
          <Route
            path={DASHBOARD}
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Dashboard />} />}
          />
          <Route
            path={MAP}
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<MapComponent />} />}
          />
          <Route
            path={USER}
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Users />} />}
          />
          <Route
            path={PREMISE}
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Premise />} />}
          />
          <Route
            path={`${RESERVATION_ANALYTICS}/:premiseId`}
            element={
              <ProtectedRoute {...defaultProtectedRouteProps} outlet={<ReservationAnalytics />} />
            }
          />
          <Route
            path={RESERVATIONS}
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Reservations />} />}
          />
          <Route
            path={NOTIFICATIONS}
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Notifications />} />}
          />
        </Route>
        <Route path='*' element={<Navigate to={AUTH_BASE_ROUTE} replace />} />
      </Routes>
      <CustomModal />
    </div>
  );
}

export default App;
