import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DateRangeFilterContainer,
  DateRangeTitle,
  DatePickerWrapper,
  DatePickerLabel,
  StyledDatePicker,
  FilterLabel,
  DatePickersContainer,
} from './Analytics.style';

interface DateRangeFilterProps {
  fromDateValue?: Date;
  toDateValue?: Date;
  onChange: (fromDate: Date | null, toDate: Date | null) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  onChange,
  fromDateValue,
  toDateValue,
}) => {
  const [fromDate, setFromDate] = useState<Date | null>(fromDateValue || null);
  const [toDate, setToDate] = useState<Date | null>(toDateValue || null);

  useEffect(() => {
    fromDateValue && setFromDate(fromDateValue);
    toDateValue && setToDate(toDateValue);
  }, [fromDateValue, toDateValue]);

  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date);
    onChange(date, toDate);
  };

  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
    onChange(fromDate, date);
  };

  return (
    <DateRangeFilterContainer>
      <FilterLabel>Filter by Date:</FilterLabel>
      <DatePickersContainer>
        <DatePickerWrapper>
          <DatePickerLabel>From:</DatePickerLabel>
          <StyledDatePicker
            selected={fromDate}
            onChange={handleFromDateChange}
            selectsStart
            startDate={fromDate ?? undefined}
            endDate={toDate ?? undefined}
            dateFormat='dd.MM.yyyy' // {{ edit_1 }}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePickerLabel>To:</DatePickerLabel>
          <StyledDatePicker
            selected={toDate}
            onChange={handleToDateChange}
            selectsEnd
            startDate={fromDate ?? undefined}
            endDate={toDate ?? undefined}
            minDate={fromDate ?? new Date(2020, 0, 1)}
            dateFormat='dd.MM.yyyy' // {{ edit_1 }}
          />
        </DatePickerWrapper>
      </DatePickersContainer>
    </DateRangeFilterContainer>
  );
};

export default DateRangeFilter;
